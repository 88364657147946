import { Header, Spacer, Text } from '@truepill/react-capsule'
import { ButtonCard, PlusCircle } from 'Components/Fragments/ButtonCard'
import { PatientAddress } from 'interfaces'
import React, { useState } from 'react'

import { keys } from '../../constants/keyboard'
import { useContentfulTheme } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { Result } from '../../hooks/useUserAddresses'
import { AddAddressResponse } from '../../services'
import { ItemCard, ItemCardWrapper } from '../Fragments/ItemCard'
import AddressForm from './AddressForm'
import DeleteAddress from './DeleteAddress'
import { ModalContainer, ShowMoreAddresses, StyledBannerAlert } from './styledComponents'

interface Props {
  addressList: PatientAddress[]
  maxAddressesDisplayed?: number
  isCheckoutFlow?: boolean
  selectedAddress?: PatientAddress | null
  onSelectedAddress?: (address: PatientAddress) => void
  onAddAddress: (address: PatientAddress) => Promise<Result<AddAddressResponse>>
  onEditAddress: (address: PatientAddress) => Promise<Result<boolean | null>>
  onSetDefaultAddress: (address: PatientAddress) => Promise<Result<boolean>>
  onDeleteAddress: (address: PatientAddress) => Promise<Result<boolean>>
}

const SavedAddresses: React.FC<Props> = ({
  addressList,
  maxAddressesDisplayed,
  isCheckoutFlow,
  selectedAddress,
  onSelectedAddress,
  onAddAddress,
  onEditAddress,
  onSetDefaultAddress,
  onDeleteAddress,
}) => {
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false)
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false)
  const [openDeleteAddressModal, setOpenDeleteAddressModal] = useState(false)
  const [showMoreAddresses, setShowMoreAddresses] = useState(false)
  const [editFormValues, setEditFormValues] = useState<PatientAddress>()
  const [existingAddressId, setExistingAddressId] = useState<number | null>()
  const { trackButtonClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const handleOpenAddAddressModal = () => {
    trackButtonClickEvent('open_add_new_address_modal', 'Add New Address')
    setOpenAddAddressModal(true)
  }

  const handleOpenEditAddressModal = (address: PatientAddress): void => {
    trackButtonClickEvent('open_edit_address_modal', 'Edit')
    setEditFormValues(address)
    setOpenEditAddressModal(true)
  }

  const handleOpenDeleteAddressModal = (address: PatientAddress) => {
    trackButtonClickEvent('open_delete_address_modal', 'Delete', 'opens delete address modal')
    setEditFormValues(address)
    setOpenDeleteAddressModal(true)
  }

  const handleAddAddress = async (address: PatientAddress): Promise<{ error?: string }> => {
    trackButtonClickEvent('submit_add_address', 'Add')
    const isAlreadySaved = addressList.find((item) => {
      return item.name === address.name && item.address1 === address.address1
    })

    if (isAlreadySaved) {
      setOpenAddAddressModal(false)
      setExistingAddressId(isAlreadySaved.id)
      return { error: undefined }
    }

    const { error } = await onAddAddress(address)
    window.scrollTo(0, 0)
    return { error }
  }

  const handleEditAddress = async (address: PatientAddress): Promise<{ error?: string }> => {
    const { data, error } = await onEditAddress(address)
    window.scrollTo(0, 0)
    if (data === null) {
      closeModalHandler()
      return {}
    }
    trackButtonClickEvent('submit_edit_address', 'Update')
    return { error }
  }

  const handleSetDefaultAddress = async (address: PatientAddress) => {
    trackButtonClickEvent('set_default_address', 'Set as Default', 'sets address as default address')
    await onSetDefaultAddress(address)
    window.scrollTo(0, 0)
  }

  const handleDeleteAddress = async (address: PatientAddress) => {
    trackButtonClickEvent('confirm_delete_address', 'Yes, delete address', 'confirms deletes address')
    closeModalHandler()
    window.scrollTo(0, 0)
    return onDeleteAddress(address)
  }

  const closeModalHandler = () => {
    setOpenAddAddressModal(false)
    setOpenEditAddressModal(false)
    setOpenDeleteAddressModal(false)
    setEditFormValues(undefined)
  }

  const handleShowMoreAddressesKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ([keys.ENTER, keys.SPACE].includes(e.key)) {
      setShowMoreAddresses(!showMoreAddresses)
    }
    e.preventDefault()
  }

  return (
    <>
      <ItemCardWrapper data-testid='addresses' className='gap-md tablet:grid-cols-2 grid grid-cols-1'>
        {addressList.length > 0 &&
          addressList.map((address: PatientAddress, index: number) => (
            <ItemCard
              key={address.id}
              itemSubject='address'
              heading={address.name}
              line1={<Text>{`${address.address1} ${address.address2 ?? ''}`}</Text>}
              line2={<Text>{`${address.city}, ${address.state} ${address.zip}`}</Text>}
              defaultChipText={`Default`}
              isDefault={address.isDefault}
              setDefault={() => handleSetDefaultAddress(address)}
              editHandler={() => handleOpenEditAddressModal(address)}
              deleteHandler={() => handleOpenDeleteAddressModal(address)}
              hidden={!showMoreAddresses && !!maxAddressesDisplayed && index > maxAddressesDisplayed - 1}
              isHighlighted={existingAddressId === address.id}
              {...(onSelectedAddress && {
                onClick: () => onSelectedAddress(address),
                isSelected: selectedAddress?.address1 === address.address1,
                activeBorderKey: 'isSelected',
              })}
            />
          ))}
        {addressList.length < 8 && (
          <ButtonCard
            text='Add new address'
            icon={<PlusCircle className='w-md text-cap-info-dark mb-2xs inline-block' />}
            ariaLabel='add new address button'
            onClick={handleOpenAddAddressModal}
          />
        )}
      </ItemCardWrapper>
      {openAddAddressModal && (
        <ModalContainer isOpen={openAddAddressModal} aria-label='Add New Address Modal' onDismiss={closeModalHandler}>
          <Header data-testid='add-address-header' variant='4xl'>
            Add new address
          </Header>
          <Spacer size='lg' />
          <AddressForm submitButtonText='Add' onCancel={closeModalHandler} onSubmit={handleAddAddress} askDefault askShouldSave={isCheckoutFlow} />
        </ModalContainer>
      )}
      {openEditAddressModal && (
        <ModalContainer isOpen={openEditAddressModal} aria-label='Edit Address Modal' onDismiss={closeModalHandler}>
          <Header data-testid='edit-address-header' variant='4xl'>
            Edit an address
          </Header>
          <Spacer size='lg' />
          <AddressForm addressToEdit={editFormValues} submitButtonText='Update' onCancel={closeModalHandler} onSubmit={handleEditAddress} />
        </ModalContainer>
      )}
      {openDeleteAddressModal && editFormValues && (
        <ModalContainer isOpen={openDeleteAddressModal} aria-label='Delete Address Modal' onDismiss={closeModalHandler}>
          <DeleteAddress currentAddress={editFormValues} cancel={closeModalHandler} handleDeleteAddress={handleDeleteAddress} />
        </ModalContainer>
      )}
      {/* TODO: Should show most recently used addresses, currently naivly truncates */}
      {!!maxAddressesDisplayed && addressList.length > maxAddressesDisplayed && (
        <ShowMoreAddresses
          onClick={() => setShowMoreAddresses(!showMoreAddresses)}
          onKeyDown={handleShowMoreAddressesKeyDown}
          color={theme.colors['primary-700']}
        >
          {showMoreAddresses ? 'Show less addresses...' : 'Show more addresses...'}
        </ShowMoreAddresses>
      )}

      {existingAddressId && (
        <StyledBannerAlert
          visible={true}
          state={'warning'}
          timeout={5000}
          onDismiss={() => setExistingAddressId(null)}
          onTimeout={() => setExistingAddressId(null)}
        >
          Looks like we already have this address listed below.
        </StyledBannerAlert>
      )}
    </>
  )
}

export default SavedAddresses
